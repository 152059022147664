import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
import DriverList from "@/views/project/lghd/ylgl/driver/DriverList";
const FleetCardHelper = defineComponent({
    name:'FleetCard',
    title:'车队',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            cityData:[],
            cysData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: true,
                details:['car','driver'],
                modelPath: "/fleet"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                channelId: [
                    {required: true, message: "请选择所属渠道", trigger: "blur" }
                ],
                cysId: [
                    {required: true, message: "请选择所属承运商", trigger: "blur" }
                ],
                name: [
                    {required: true, message: "请输入车队名称", trigger: "blur" }
                ],
                contacts: [
                    {required: true, message: "请输入负责人", trigger: "blur" }
                ],
                telephone: [
                    // {required: true, message: "请输入联系电话", trigger: "blur" }
                    { required: true,validator: utils.$$str.checkPhone, trigger: 'blur' }
                ],
                jsType: [
                    {required: true, message: "请选择结算周期", trigger: "blur" }
                ],
                khh: [
                    {required: true, message: "请输入开户行", trigger: "blur" }
                ],
                cardNum: [
                    {required: true, message: "请输入卡号", trigger: "blur" }
                ],
                cityId: [
                    {required: true, message: "请选择所在城市", trigger: "blur" }
                ],
                yqm: [
                    {required: true, message: "请输入邀请码", trigger: "blur" }
                ],
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------

        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'cysId'==params.comboId){
                    return dataObj.cysData;
                }
                if(params && 'jsType'==params.comboId){
                     return [{value:0,label:'日结'},{value:1,label:'月结'}]
                }

                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData;
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType) => {
                return {
                    detailParam: {
                        canAdd:false,
                        title:FleetCardHelper.title,
                        readOnly:'driver'==detailType?true:dataObj.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: "/fleet/detail?t="+Math.random()*1000
                    }
                }
            }
        })

        const buildCysData=async ()=>{
            dataObj.cysData= await utils.$$api.getCysByChannelId({});
        }

        const buildCityData=async (cysId)=>{
            dataObj.cityData = await utils.$$api.getCitiesByCysId({cysId:cysId});
        }
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue,selectId)=>{
            if('cysId'==selectId){
                dataObj.form.cityId='';
                await buildCityData(newValue);
            }
            if('jsType'==selectId){}
            if('cityId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            await buildCysData();
            if('/load'==addOrLoad){
                await buildCityData(data.data.cysId);
            }
        }


        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        //---------------数据表格行格式化
        const formatterDataZjcxType=(row, column, value, index)=>{
            if (0 == value) {
                return "A1";
            } else if (1 == value) {
                return "A2";
            } else if (2 == value) {
                return "A3";
            } else if (3 == value) {
                return "B1";
            } else if (4 == value) {
                return "B2";
            } else {
                return "";
            }
        }

        const buildClaimDialog=async (row)=>{
            let options={
                modelComp: DriverList,
                ownerComp:proxy,
                title:"选择司机",
                from:"fleetCard",
                fleetId:dataObj.form.id,
                carId:row['F_ID'],
                // contentHeight:200,
                showFooterBtn:true
            }
            let vm = await utils.$$dialog.create(options);
            vm.dialogVisible = true;
        }
        const sureHandler=async (modelInst)=>{
            let selectRows = modelInst.pageListRef.getTbInst().getSelection();
            if(selectRows.length>0){
                utils.$$tools.configBox({
                    msgContent:'确定执行该操作吗?',
                    fn:async ()=> {
                        let url = dataObj.compParams.modelPath + "/custom";
                        let res = await utils.$$api.postRequest({url: url, params: {operateType:'addOrModifyDriver',carId:modelInst.carId,driverId: selectRows[0]['F_ID']}});
                        if(res.result){
                            await dataObj.refMap.get('car').reloadGrid();
                            utils.$$tools.success();
                        }
                    }
                });
            }
            return true;
        }
        const addDriverHandler=async (row)=>{
            await buildClaimDialog(row);
        }
        const modifyDriverHandler=async (row)=>{
            await buildClaimDialog(row);
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,cardDetailParam,sureHandler,
            beforeOpen,beforeSaveHandler,startHandler,stopHandler,formatterDataZjcxType,addDriverHandler,modifyDriverHandler
         //,gridLoaded
        }
    }
});
export default FleetCardHelper;