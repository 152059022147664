<template>
    <div class="Fleet">
        <el-form ref="formRef" status-icon :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="所属承运商" prop="cysId">
                        <el-select :ref="el=>refMap.set('cysId',el)" v-model="form.cysId" clearable placeholder="所属承运商" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cysId')}">
                            <el-option v-for="item in cysData" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="城市" prop="cityId">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="form.cityId" clearable placeholder="请选择城市" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cityId')}">
                            <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车队名称" prop="name">
                        <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入车队名称" maxlength="6" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="负责人" prop="contacts">
                        <el-input @input="e => form.contacts = validForbid(e)" v-model="form.contacts" placeholder="请输入负责人" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="telephone">
                        <el-input @input="e => form.telephone = validForbid(e)" v-model="form.telephone" placeholder="请输入联系电话" maxlength="13" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="结算周期" prop="jsType">
                        <el-select :ref="el=>refMap.set('jsType',el)" v-model="form.jsType" clearable placeholder="请选择结算周期" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'jsType')}">
                            <el-option v-for="item in comboSelect({comboId:'jsType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="登录账号" prop="code">
                        <el-input @input="e => form.code = validForbid(e)" v-model="form.code" placeholder="请输入登录账号" maxlength="30" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="登录密码" prop="pwd">
                        <el-input @input="e => form.pwd = validForbid(e)" v-model="form.pwd" type="password" placeholder="请输入登录密码" maxlength="32" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="开户行" prop="khh">
                        <el-input @input="e => form.khh = validForbid(e)" v-model="form.khh" placeholder="请输入开户行" maxlength="50" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="卡号" prop="cardNum">
                        <el-input @input="e => form.cardNum = validForbid(e)" v-model="form.cardNum" placeholder="请输入卡号" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <div style="display:flex;align-items: center;">
                        <div style="flex: 1">
                            <el-form-item label="邀请码" prop="yqm">
                                <!--<el-input @input="e => form.yqm = validForbid(e)" v-model="form.yqm" placeholder="自动生成"  maxlength="10" :disabled="disabled" clearable/>-->
                                <el-input-number v-model="form.yqm"  :min="10001" :max="9999999" :disabled="disabled" :controls="false" style="width: 100%"/>
                            </el-form-item>
                        </div>
                        <div style="width: 50px;font-size: 12px;">
                            <el-tooltip placement="top">
                                <template #content>
                                   【车队码规则：1 + 区号 + 01～99】示例：<br />
                                    &nbsp;重庆 102301<br />
                                    &nbsp;成都 102801<br />
                                    &nbsp;郑州 1037101<br />
                                    &nbsp;青岛 1053201<br />
                                    &nbsp;武汉 102701<br />
                                </template>
                                <i class="el-icon-edit">示例</i>&nbsp;&nbsp;
                            </el-tooltip>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-tabs>
            <el-tab-pane label="车辆">
                <EditTable :ref="el=>refMap.set('car',el)" v-bind="cardDetailParam('car')">
                    <template v-slot:fixCol>
                        <slot name="fixCol">
                            <el-table-column fixed="left" header-align="center" align="center" label="操作" width="140">
                                <template #default="scope">
                                    <div class="editDel">
                                        <el-button @click="addDriverHandler(scope.row)" type="text" v-if="scope.row.F_DRIVER_ID==''">
                                            <div style="color:#409eff"><i class="iconfont icon-zengjia"/>&nbsp;&nbsp;添加司机</div>
                                        </el-button>
                                        <el-button @click="modifyDriverHandler(scope.row)" type="text" v-else>
                                            <div style="color:#409eff"><i class="el-icon-edit"/>&nbsp;&nbsp;更改司机</div>
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </slot>
                    </template>

                    <template v-slot:tbCols>
                        <el-table-column label="合作形式" prop="F_COOPERATION_TYPE"/>
                        <el-table-column label="车型" prop="F_CX_ID"/>
                        <el-table-column label="车牌号" prop="F_CAR_NUM"/>
                        <el-table-column label="所有人" prop="F_OWNER"/>
                        <el-table-column label="年检时间" prop="F_NJ_TIME"/>
                        <el-table-column label="默认司机" prop="F_DRIVER_ID"/>
                        <el-table-column label="审核状态" prop="F_STATUS"/>
                        <el-table-column label="是否停用" prop="F_FLAG"/>
                    </template>
                </EditTable>
            </el-tab-pane>
            <el-tab-pane label="司机">
                <EditTable :ref="el=>refMap.set('driver',el)" v-bind="cardDetailParam('driver')">
                    <template v-slot:tbCols>
                        <el-table-column label="司机名称" prop="F_NAME"/>
                        <el-table-column label="准驾车型" prop="F_ZJCX_TYPE" :formatter="formatterDataZjcxType"/>
                        <el-table-column label="注册手机" prop="F_PHONE"/>
                        <el-table-column label="身份证号" prop="F_SFZ"/>
                        <el-table-column label="创建时间" prop="F_TIME"/>
                        <el-table-column label="审核状态" prop="F_STATUS"/>
                        <el-table-column label="是否停用" prop="F_FLAG"/>
                    </template>
                </EditTable>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>


<script>
    import FleetCardHelper from "./FleetCardHelper.js";
    export default FleetCardHelper;
</script>

<style scoped>
    .Fleet{
        width: 100%;
    }
</style>
